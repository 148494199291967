<template
  ><div class="rating">
    <div
      class="r-dl"
      v-for="(item, index) in items"
      :key="index + '' + item.id"
    >
      <div class="r-dt">{{ item.text }}</div>
      <Star
        @starUpdate="data.values.find(it => it.id === item.id).value = $event"
        :star="data.values.find(it => it.id === item.id).value"
      ></Star>
    </div>
  </div>
</template>

<script>
import Star from "@/components/feedback/ui/Star";
export default {
  name: "Stars",
  components: { Star },
  data: () => {
    return {
      data: {
        values: []
      }
    };
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    other: {
      type: Boolean,
      default: () => false
    },
    questionId: {
      type: Number,
      default: () => 0
    }
  },
  mounted() {
    for (let i = 0; i < this.items.length; i++) {
      this.data.values.push({ id: this.items[i].id, value: 0 });
    }
  },
  methods: {
    getAnswer() {
      let answer = [];
      for (let i = 0; i < this.data.values.length; i++) {
        answer.push({
          questionnaireId:+localStorage.getItem("questionareId"),
          questionId: this.questionId,
          answer: this.data.values[i].value,
          optionId: this.data.values[i].id
        });
      }
      return answer;
    },
    setStar(id, index) {
      this.data.values[id] = index;
    },
    getStar(id, index) {
      console.log(this.data.values[id] >= index);
      return this.data.values[id] >= index;
    }
  }
};
</script>

<style scoped></style>
